import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    const promise = $tpu.r.checkItems.create(
      properties.checklistId,
      properties.formData)
    this.executeCardUpdate(promise, properties, component, true).then(() => {
      setTimeout(() => {
        // Reenable text field for the next item after it gets removed (emptied).
        $tpu.editing = { newItemChecklistId: properties.checklistId }
        this.refreshCard(true)
      })
    })
  }
}
